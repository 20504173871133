<template>
  <div id="page">
    <NavHeader/>
    <main role="main">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </main>
    <Footer/>
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    NavHeader,
    Footer
  }
}
</script>

<style lang="sass">
@font-face
  font-family: "Acherus Grotesque"
  src: url('assets/fonts/acherusgrotesque-regular-webfont.woff2') format('woff2'), url('assets/fonts/acherusgrotesque-regular-webfont.woff') format('woff')

.s1
  letter-spacing: .2rem
  font-weight: bold !important

html,
body
  height: 100%
  font-family: 'Acherus Grotesque', sans-serif !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  //background-image: url("assets/images/bg-light.png")
  //background-color: #aaa

  #page
    height: 100%
    display: flex
    flex-direction: column

    main
      flex: 1

      > div
        position: absolute
        width: 100vw
        min-height: 100vh
        padding-bottom: 56px

      .s1-lore
        p
          max-width: 600px
          text-shadow: #000000 0 1px 2px, #999999 0 0 3px

  section
    color: #2c3e50

  footer.footer
    padding: 1rem
    position: fixed
    bottom: 0
    left: 0
    width: 100vw

.is-wrappable
  flex-wrap: wrap

.s1-button-mobile
  @media screen and (max-width: 425px)
    width: 100%
</style>
