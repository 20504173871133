<template>
  <b-navbar wrapper-class="content container" fixed-top>
    <template slot="brand">
      <router-link to="/" class="navbar-item">
        <img src="@/assets/images/s1-logo@2x.png" alt="S1 Produções">
      </router-link>
    </template>
    <template slot="end">
      <router-link to="/" class="navbar-item">Início</router-link>
      <router-link to="/sobre" class="navbar-item">Sobre</router-link>
      <router-link to="/casamentos" class="navbar-item">Casamentos</router-link>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "NavHeader"
}
</script>

<style lang="sass">
div.navbar-brand
  @media screen and (min-width: 1024px)
    margin-left: 0 !important

  a
    padding: 0.3rem 0.75rem !important

    img
      min-height: 2rem
</style>