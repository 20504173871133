import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        gtm: 'Home'
      }
    },
    {
      path: '/batalhas-nerds',
      name: 'batalhasNerds4',
      component: () => import('./views/eventos/BatalhasNerds4'),
      children: [
        {
          path: "/",
          redirect: "programacao",
          meta: {gtm: 'Batalhas Nerds 4'}
        },
        {
          path: "negocios/cadastro",
          redirect: "negocios"
          // component: () => import('./views/eventos/sections/bn4NegociosCadastro')
        },
        {
          path: 'negocios',
          name: "batalhasNerds4Negocios",
          component: () => import('./views/eventos/sections/bn4NegociosCatalogo'),
          meta: {gtm: 'Batalhas Nerds 4 - Catálogo'}
        },
        {
          path: 'programacao',
          name: "batalhasNerds4Programacao",
          component: () => import('./views/eventos/sections/bn4Programacao'),
          meta: {gtm: 'Batalhas Nerds 4 - Programação'}
        },
        {
          path: 'causas',
          name: "batalhasNerds4Causas",
          component: () => import('./views/eventos/sections/bn4CausasSociais'),
          meta: {gtm: 'Batalhas Nerds 4 - Causas Sociais'}
        }
      ]
    },
    {
      path: '/evento/cabare',
      name: 'cabare',
      component: () => import('./views/eventos/CabareSocaite'),
      meta: {gtm: 'Cabaré Soçaite'}
    },
    {
      path: '/evento/divas',
      name: 'divas',
      component: () => import('./views/eventos/DivasBattle'),
      meta: {gtm: 'Divas Battle'}
    },
    {
      path: '/evento/tanabata',
      name: 'tanabata',
      component: () => import('./views/eventos/Tanabata'),
      meta: {gtm: 'Tanabata'}
    },
    {
      path: '/evento/nerdpride',
      name: 'nerdpride',
      component: () => import('./views/eventos/NerdPride'),
      meta: {gtm: 'Nerdpride'}
    },
    {
      path: '/evento/batalhas-nerds',
      name: 'batalhasNerds',
      component: () => import('./views/eventos/BatalhasNerds'),
      meta: {gtm: 'Batalhas Nerds'}
    },
    // {
    //     path: '/evento/calourada-hogwarts',
    //     name: 'calouradaHogwarts',
    //     component: () => import('./views/eventos/CalouradaHogwarts')
    // },
    // {
    //     path: '/evento/got-na-orbita',
    //     name: 'gotNaOrbita',
    //     component: () => import('./views/eventos/GotNaOrbita')
    // },
    // {
    //     path: '/evento/the-last-day',
    //     name: 'theLastDay',
    //     component: () => import('./views/eventos/TheLastDay')
    // },
    // {
    //     path: '/evento/dgg',
    //     name: 'dgg',
    //     component: () => import('./views/eventos/DGG')
    // },
    // {
    //     path: '/evento/mad-tea',
    //     name: 'madTea',
    //     component: () => import('./views/eventos/MadTea')
    // },
    {
      path: '/sobre',
      name: 'sobre',
      component: () => import('./views/Sobre'),
      meta: {gtm: 'Sobre'}
    },
    {
      path: '/casamentos',
      name: 'casamentos',
      component: () => import('./views/Casamentos'),
      meta: {gtm: 'Casamentos'}
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return !['home', 'batalhasNerds4Negocios', 'batalhasNerds4Programacao', 'batalhasNerds4Causas'].includes(to.name) || ['home', 'batalhasNerds'].includes(from.name) ? {x: 0, y: 0} : savedPosition
  }
})

router.beforeEach((to, from, next) => {
  const baseTitle = 'S1 Produções'

  if (!['home'].includes(to.name)) {
    document.title = `${baseTitle} | ${to.meta.gtm}`
  } else if(to.name === 'home' && from.name !== null) {
    document.title = baseTitle
  }
  next()
})

export default router