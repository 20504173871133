import {required, email, min} from "vee-validate/dist/rules"
import {extend} from "vee-validate"

extend("required", {
    ...required,
    message: "Esse campo é obrigatório"
})

extend("email", {
    ...email,
    message: "Email inválido"
})

extend("tel", {
    ...min,
    message: "Use um telefone com DDD"
})
