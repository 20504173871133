<template>
  <section class="section">
    <Destaques />
  </section>
</template>

<script>
  import Destaques from '@/components/sections/Destaques'

  export default {
    name: "Festas",
    components: {
      Destaques
    }
  }
</script>

<style lang="sass" scoped>
  section
    min-height: 100vh
    scroll-snap-align: start

    background-image: linear-gradient(112deg, rgba(183, 33, 255, 0.6993172268907564) 0%, rgba(33, 212, 253, 0.7) 100%), url("../../assets/images/bg-festas.jpg")
    background-position: center
    background-size: cover
</style>