<template>
  <kinesis-container class="tile is-ancestor is-vertical">
    <div class="tile">
      <div class="tile is-parent">
        <div
            :cycle="3"
            class="tile is-child box s1-tile-cabare s1-has-link"
            @click="$router.push('evento/cabare')">
          <div class="content">
            <h2>Cabaré Soçaite</h2>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div
            class="tile is-child box s1-tile-divas s1-has-link"
            @click="$router.push('evento/divas')">
          <div class="content">
            <h2>Divas Battle</h2>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div
            type="depth"
            class="tile is-child box s1-tile-tanabata s1-has-link"
            @click="$router.push('evento/tanabata')">
          <div class="content">
            <h2>Festival Tanabata</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box s1-tile-nerd-pride s1-has-link"
             @click="$router.push('evento/nerdpride')">
          <div class="content">
            <h2>Nerd Pride</h2>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div
            type="depth"
            class="tile is-child box s1-tile-star-wars">
          <div class="content">
            <h2>Star Wars</h2>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box s1-tile-batalhas-nerds s1-has-link"
             title="Batalhas Nerds"
             @click="$router.push('evento/batalhas-nerds')">
          <div class="content">
            <!--<h2>Batalhas Nerds</h2>-->
          </div>
        </div>
      </div>


    </div>
    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box s1-tile-sonata">
          <div class="content">
            <h2>Sonata Arctica</h2>
          </div>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child box s1-tile-dgg">
          <div class="content">
            <!--<h2>Trio da Uber</h2>-->
          </div>
        </div>
      </div>

    </div>
  </kinesis-container>
</template>

<script>
import {KinesisContainer} from 'vue-kinesis'

export default {
  name: "Destaques",
  components: {
    KinesisContainer
  }
}
</script>

<style lang="sass" scoped>
@import "~bulma/sass/utilities/all"
.s1-has-link
  &:hover
    cursor: pointer
    box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 10px rgba($black, 0.6)
    transform: scale(1.05)

%tile
  background-size: cover
  background-position: center
  padding-top: 56.078%
  transition: box-shadow .5s, transform .5s

  h2
    color: white

.s1-tile-cabare
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-cabare.jpg')

.s1-tile-divas
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-divas.jpg')

.s1-tile-nerd-pride
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-nerd-pride.jpg')

.s1-tile-star-wars
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-star-wars.jpg')

.s1-tile-batalhas-nerds
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-batalhas-nerds.jpg')

.s1-tile-tanabata
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-tanabata.jpg')

.s1-tile-sonata
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-sonata.jpg')

.s1-tile-dgg
  @extend %tile
  background-image: url('../../assets/images/tiles/tile-dgg.jpg')


</style>