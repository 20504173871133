<template>
  <div>
    <Hero></Hero>
    <Festas></Festas>
  </div>
</template>

<script>
import Hero from '@/components/sections/Hero'
import Festas from '@/components/sections/Festas'

export default {
  name: 'Home',
  components: {
    Hero,
    Festas
  }
}
</script>

<style lang="sass">

</style>