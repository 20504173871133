import Vue from 'vue'
import Buefy from 'buefy'
import VueCarousel from 'vue-carousel'
import VueGtm from 'vue-gtm'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import {Integrations} from "@sentry/tracing";
import './s1-styles.sass'
import './vee-validate.rules'

Vue.config.productionTip = false
Vue.use(VueCarousel)
Vue.use(Buefy)
Vue.use(VueGtm, {
  id: "GTM-WMWJ8DK",
  vueRouter: router
});

Sentry.init({
  Vue,
  dsn: "https://bc96cbd932934c909f22a32689f98133@o1048859.ingest.sentry.io/6052481",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "s1producoes.com.br", "s1.pro.br", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
