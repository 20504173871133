<template>
  <carousel
          :per-page="slides.perPage"
          :autoplay="slides.autoplay"
          :loop="slides.loop"
          :autoplay-timeout="slides.autoPlayTimout"
          paginationActiveColor="#ffffff"
          paginationColor="#c6c6c6"
          id="hero">
    <slide>
      <section class="hero is-large" id="heroEquipe" @click="$router.push('/sobre')">
        <div class="hero-body">
          <div class="container is-fullhd">
            <h1 class="title">Mais do que mil, somos Um</h1>
            <h2 class="subtitle">Produzimos, criamos e agenciamos todo tipo de evento</h2>
          </div>
          <small class="photo-credits has-text-light">Foto: Yago Resende</small>
        </div>
      </section>
    </slide>
    <slide>
      <section class="hero is-large is-bold s1-has-link" id="heroCasamentos" @click="$router.push('/casamentos')">
        <div class="hero-body">
          <div class="container is-fullhd">
            <h1 class="title">Casamento</h1>
            <h2 class="subtitle">Você sonha, a gente produz</h2>
          </div>
            <small class="photo-credits has-text-light">Foto: Rafael Holanda</small>
        </div>
      </section>
    </slide>
  </carousel>
</template>

<script>
  export default {
    name: "Hero",
    data: () => {
      return {
        slides: {
          perPage: 1,
          autoplay: true,
          loop: true,
          autoPlayTimout: 4000
        }
      }
    }
  }

</script>

<style lang="sass">
  %heroCover
    background-position: center
    background-size: cover

  #hero
    .hero
      height: 100%

      @media screen and (max-width: 768px)
        height: 56.25vw

      .title,
      .subtitle
        color: #fff
        text-shadow: 0 1px 3px #555

      &.s1-has-link:hover
        cursor: pointer

      &#heroEquipe
        @extend %heroCover
        background-position: top
        background-image: linear-gradient(356deg, rgba(242, 104, 33, 0.7) 0%, rgba(255, 198, 19, 0.7) 100%), url("../../assets/images/hero-equipe.jpg")

      &#heroCasamentos
        @extend %heroCover
        background-position: bottom
        background-image: linear-gradient(47deg, rgba(122, 104, 230, 0.7) 0%, rgba(255, 198, 19, 0.7) 100%), url("../../assets/images/hero-casamentos.jpg")

    &.VueCarousel
      .VueCarousel-pagination
        position: absolute !important
        bottom: 0 !important

    .hero-body
      position: relative
      .photo-credits
        position: absolute
        left: 0.5rem
        bottom: 0.3rem
</style>