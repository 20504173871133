<template>
  <footer class="footer">
    <div class="content container is-fullhd">
      <p class="has-text-centered">&copy; 2022 <span class="s1"><span class="has-text-primary">S1</span>Produções</span></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>
